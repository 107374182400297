module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Daten-Strom-GP | Planung und Installation von Datennetzen sowie Elektroanlagen","short_name":"Daten-Strom-GP","start_url":"/","icon":"src/images/logo-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f9a1f9db611f0f974db0e01f94651604"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
